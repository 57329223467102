import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import AboutPage from '@/views/AboutPage.vue';
import ProductsPage from '@/views/ProductsPage.vue';
import GalleryPage from '@/views/GalleryPage.vue';
import ContactPage from '@/views/ContactPage.vue';

const routes = [
  { 
    path: '/', 
    name: 'Home', 
    component: HomePage,
    meta: { title: 'Abir International - Biggest Trade Company in Wood Polish Industries from Bangladesh' },
  },
  { 
    path: '/about', 
    name: 'About', 
    component: AboutPage,
    meta: { title: 'About - Abir International' },
   },
  { 
    path: '/products', 
    name: 'Products', 
    component: ProductsPage,
    meta: { title: 'Products - Abir International' },
   },
  { 
    path: '/gallery', 
    name: 'Gallery', 
    component: GalleryPage,
    meta: { title: 'Gallery - Abir International' },
   },
  { 
    path: '/contact', 
    name: 'Contact', 
    component: ContactPage,
    meta: { title: 'Contact - Abir International' },
   },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta field
  const title = to.meta.title || 'Audius';
  // Set the document title
  document.title = title;
  next();
});

export default router;
