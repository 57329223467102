<template>
    <section class="faq-section">
      <div class="faq-container">
        <p class="faq-description">
          Abir International is a leading importer and organizer of over 15 explosive and chemical items from various renowned business hubs. With a strong presence in the United Arab Emirates, Dubai, Iran, Singapore, Malaysia, Thailand, China, and India, Abir International, under the leadership of Sir Mokbul Hossain, has earned a reputation for excellence, sincerity, and responsible business practices.
        </p>
  
        <div class="faq-item" v-for="(faq, index) in faqs" :key="index">
          <button class="faq-question" @click="toggleFaq(index)">
            <span>{{ faq.question }}</span>
            <i :class="faq.open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
          </button>
          <div v-if="faq.open" class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        faqs: [
          {
            question: 'What products does Abir International specialize in?',
            answer:
              'Abir International specializes in importing and organizing over 15 types of explosive and chemical items from various international markets.',
            open: false,
          },
          {
            question:
              'Which countries does Abir International have business relationships with?',
            answer:
              'Abir International has established strong business relationships with countries like the United Arab Emirates, Dubai, Iran, Singapore, Malaysia, Thailand, China, India, and more.',
            open: false,
          },
          {
            question: 'Who is the owner of Abir International?',
            answer:
              'Abir International is owned and led by Sir Mokbul Hossain, a respected figure known for his expertise and commitment to quality and excellence in the industry.',
            open: false,
          },
          {
            question: 'How does Abir International ensure the quality of its products?',
            answer:
              'Abir International ensures quality through stringent sourcing processes, regular quality checks, and adherence to international standards, ensuring that all products meet or exceed expectations.',
            open: false,
          },
          {
            question:
              'Can new international suppliers collaborate with Abir International?',
            answer:
              'Yes, Abir International is open to collaboration with new international suppliers who meet the company’s quality standards and business criteria. Interested parties can contact us for further discussions.',
            open: false,
          },
        ],
      };
    },
    methods: {
      toggleFaq(index) {
        this.faqs[index].open = !this.faqs[index].open;
      },
    },
  };
  </script>
  
  <style scoped>
  .faq-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
  }
  
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-description {
    font-size: 1.1rem;
    color: #555;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .faq-item {
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-question {
    width: 100%;
    background-color: #fff;
    color: #333;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1.1rem;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #f1f1f1;
  }
  
  .faq-answer {
    background-color: #fff;
    padding: 15px 20px;
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
    animation: fadeIn 0.3s ease forwards;
  }
  
  .faq-answer p {
    margin: 0;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
  