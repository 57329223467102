<template>
    <div class="slider-section">
      <!-- Slide Navigation Buttons to Choose a Slide -->
      <div class="slide-navigation">
        <button
          v-for="(slide, index) in slides"
          :key="index"
          @click="changeSlide(index)"
          :class="{ active: index === activeSlide }"
        >
          {{ slide.title }}
        </button>
        <!-- Progress Bar for Current Slide -->
        <div class="progress-bar" :style="{ width: `${progressWidth}%` }"></div>
      </div>
  
      <!-- Sliders with Titles and CTA Buttons -->
      <div
        v-for="(slide, index) in slides"
        :key="index"
        :style="{ backgroundImage: `url(${slide.image})` }"
        class="slider"
        :class="{ active: index === activeSlide }"
      >
        <div class="slider-content">
          <h1 class="slider-title">{{ slide.title }}</h1>
          <p>{{ slide.content }}</p>
          <a :href="slide.link" class="slider-cta">{{ slide.cta }}</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        slides: [
          {
            title: 'Biggest Trade Company',
            content: 'Abir International is the biggest trade company in wood polish industries from Bangladesh! We import 42 explosive and chemical items from different countries.',
            cta: 'Learn More',
            link: '/about',
            image: require('@/assets/gallery/inside1.webp'),
          },
          {
            title: 'Reliable Chemical Importer',
            content: 'We import 42 explosive and chemical items from various countries, maintaining strong partnerships, quality standards, and industry expertise.',
            cta: 'Our Products',
            link: '/products',
            image: require('@/assets/gallery/warehouse22.webp'),
          },
          {
            title: 'Worldwide Business Partners',
            content: 'We have successfully partnered with companies in many countries, expanding our fame with sincerity and responsibility. Let\'s import with us from today!',
            cta: 'Our Products',
            link: '/products',
            image: require('@/assets/gallery/inside15.webp'),
          },
          {
            title: 'Dominating Wood Polish Industries',
            content: 'We are dominating the wood polish industries in Bangladesh by ensuring quality products and timely deliveries with exceptional service and reliability.',
            cta: 'Our Products',
            link: '/products',
            image: require('@/assets/gallery/warehouse20.webp'),
          },
        ],
        activeSlide: 0,
        progressWidth: 0,
        intervalId: null,
      };
    },
    methods: {
      changeSlide(index) {
        this.activeSlide = index;
        this.resetProgress();
      },
      resetProgress() {
        clearInterval(this.intervalId);
        this.progressWidth = 0;
        this.startProgress();
      },
      startProgress() {
        this.intervalId = setInterval(() => {
          if (this.progressWidth < 100) {
            this.progressWidth += 1;
          } else {
            this.nextSlide();
          }
        }, 50);
      },
      nextSlide() {
        this.activeSlide = (this.activeSlide + 1) % this.slides.length;
        this.resetProgress();
      },
    },
    mounted() {
      this.startProgress();
    },
    beforeUnmount() {
      clearInterval(this.intervalId);
    },
  };
  </script>
  
  <style scoped>
  .slider-section {
    position: relative;
    width: 100%;
    height: 90vh;
    margin-bottom: 50px;
  }
  
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    z-index: 0;
    visibility: hidden;
    overflow: hidden;
  }
  
  .slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .slider-content {
    position: relative;
    z-index: 2;
    color: white;
    margin-left: 5%;
    animation: fadeInLeft 1s;
  }
  
  .slider.active {
    opacity: 1;
    z-index: 1;
    visibility: visible;
  }
  
  .slider-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .slider p {
    font-size: 1.2rem;
    margin-right: 60%;
  }
  
  .slider-cta {
    display: inline-block;
    padding: 10px 20px;
    background-color: #E84B37;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .slider-cta:hover {
    background-color: #b5362d;
  }
  
  .progress-bar {
    position: absolute;
    top: -7px;
    height: 5px;
    background-color: #E84B37;
    transition: width 1s linear;
  }
  
  .slide-navigation {
    position: absolute;
    justify-content: space-around;
    bottom: 5%;
    left: 2.5%;
    right: 2.5%;
    display: flex;
    gap: 5px;
    z-index: 2;
  }
  
  .slide-navigation button {
    padding: 10px 20px;
    color: #070524;
    border: none;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    transition: transform 0.3s;
  }
  
  .slide-navigation button.active {
    background-color: #E84B37;
    color: white;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .slider-content {
      margin-left: 3%;
      text-align: center;
    }
  
    .slider-title {
      font-size: 1.8rem;
    }
  
    .slider p {
      font-size: 1rem;
      margin-right: 0;
    }
  
    .slider-cta {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    .slide-navigation {
      flex-direction: column;
      align-items: center;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  
    .slide-navigation button {
      padding: 8px 12px;
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .slider-title {
      font-size: 1.5rem;
    }
  
    .slider-cta {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  
    .slide-navigation {
      gap: 2px;
    }
  }
  </style>
  