<template>
  <div>
    <PageHeading title="Gallery" subtitle="Showcasing Our Global Presence and Success" />
    <GallerySection />
  </div>
</template>

<script>
import PageHeading from '@/components/PageHeading.vue';
import GallerySection from '@/components/GallerySection.vue';

export default {
  components: {
    PageHeading,
    GallerySection
  }
}
</script>