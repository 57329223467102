<template>
    <section class="page-heading">
      <div class="container">
        <h1>{{ title }}</h1>
        <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'PageHeading',
    props: {
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
        required: false,
      },
    },
  };
  </script>
  
  <style scoped>
  .page-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/gallery/inside17.webp');
    background-size: cover;
    background-position: center;
    color: #ffffff;
    text-align: center;
    position: relative;
  }
  
  .page-heading .container {
    max-width: 1200px;
    padding: 0 15px;
    width: 100%;
  }
  
  .page-heading h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
  }
  
  .page-heading .subtitle {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    color: #e0e0e0;
    font-weight: 400;
  }
  </style>
  