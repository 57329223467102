<template>
  <header :class="['header-section', { 'header-scrolled': isScrolled }]">
    <div class="container">
      <div class="logo">
        <router-link to="/"><img src="@/assets/Abir-International.webp" alt="Logo" /></router-link>
      </div>
      <nav :class="['navigation', { 'mobile-open': isMobileMenuOpen }]">
        <ul>
          <li><router-link to="/about" @click="closeMobileMenu">About</router-link></li>
          <li><router-link to="/products" @click="closeMobileMenu">Products</router-link></li>
          <li><router-link to="/gallery" @click="closeMobileMenu">Gallery</router-link></li>
          <li><router-link to="/contact" @click="closeMobileMenu">Contact</router-link></li>
        </ul>
      </nav>
      <div class="cta">
        <a href="tel:+8801731103126"><button class="cta-button">Make a Call</button></a>
      </div>
      <button class="mobile-menu-toggle" @click="toggleMobileMenu">
        <span :class="{ 'open': isMobileMenuOpen }"></span>
        <span :class="{ 'open': isMobileMenuOpen }"></span>
        <span :class="{ 'open': isMobileMenuOpen }"></span>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderSection',
  data() {
    return {
      isScrolled: false,
      isMobileMenuOpen: false,
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.header-section {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.header-scrolled {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.logo img {
  height: 50px;
}

.navigation {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* Center the menu on larger screens */
  gap: 2rem;
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.navigation ul li {
  display: inline-block;
}

.header-section a {
  text-decoration: none;
  color: #ffffff80;
  font-weight: 500;
  font-size: 1.1rem;
}

.header-scrolled a {
  color: #333;
}

.header-section a:hover {
  color: #e84545;
}

.cta .cta-button {
  /* margin-left: 25px; */
  margin-right: 65px;
  background-color: #e84545;
  color: #ffffff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta .cta-button:hover {
  background-color: #b73535;
}

/* Mobile styles */
.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  flex-direction: row;
}

.mobile-menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.header-section .mobile-menu-toggle span {
  background-color: #FFFFFF;
}

.header-scrolled .mobile-menu-toggle span {
  background-color: #000000;
}

.mobile-menu-toggle span.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.mobile-menu-toggle span.open:nth-child(2) {
  opacity: 0;
}

.mobile-menu-toggle span.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navigation {
  display: flex;
  gap: 2rem;
}

.navigation.mobile-open {
  display: flex;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background: transparent;
  padding: 1rem;
  flex-direction: row;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

@media (max-width: 768px) {
  .cta .cta-button {
  margin-left: 25px;
  margin-right: 25px;
  }

  .navigation {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .navigation.mobile-open {
    display: flex;
  }
}
</style>