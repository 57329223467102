<template>
  <div class="contact-container">
    <!-- Contact Info Section -->
    <div class="contact-info">
      <p class="summary">
        Feel free to reach out to us for any inquiries or support. We're here to assist you and ensure your experience with us is smooth and satisfactory.
      </p>
      <div class="info-box">
        <i class="fas fa-envelope"></i>
        <h3>Email</h3>
        <p>info@abirinternational.org</p>
      </div>
      <div class="info-box">
        <i class="fas fa-phone"></i>
        <h3>Phone</h3>
        <p>(+880) 1731 10 3126</p>
      </div>
    </div>

    <!-- Contact Form Section -->
    <div class="contact-form">
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" id="name" v-model="form.name" required />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="form.email" required />
        </div>
        <div class="form-group">
          <label for="message">Message</label>
          <textarea id="message" v-model="form.message" required></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    submitForm() {
      // Handle form submission
      alert('Message sent!');
    }
  }
};
</script>

<style>
.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.contact-info, .contact-form {
  flex: 1;
  min-width: 300px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.summary {
  margin-bottom: 20px;
  color: #555;
  font-size: 16px;
}

.info-box {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.info-box i {
  font-size: 24px;
  color: #e84545; /* Icon color */
}

.info-box h3 {
  margin: 0;
  color: #333;
}

.info-box p {
  margin: 0;
  color: #555;
}

.contact-form h2 {
  margin-bottom: 20px;
  color: #333;
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.contact-form button {
  background-color: #e84545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.contact-form button:hover {
  background-color: #d74040;
}
</style>
