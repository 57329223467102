<template>
  <div id="app">
    <HeaderSection />
    <router-view />
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'App',
  components: {
    HeaderSection,
    FooterSection
  }
}
</script>

<style>
/* Global styles can go here */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
