<template>
  <section class="gallery-section">
    <div class="container">
      <div class="category-menu">
        <button @click="filterCategory('all')" :class="{ active: currentCategory === 'all' }">All</button>
        <button @click="filterCategory('office-inside')" :class="{ active: currentCategory === 'office-inside' }">Office (Inside)</button>
        <button @click="filterCategory('office-outside')" :class="{ active: currentCategory === 'office-outside' }">Office (Outside)</button>
        <button @click="filterCategory('warehouse')" :class="{ active: currentCategory === 'warehouse' }">Warehouse</button>
        <button @click="filterCategory('eco-park')" :class="{ active: currentCategory === 'eco-park' }">Eco Park</button>
      </div>

      <div class="gallery-grid">
        <div v-for="image in filteredImages" :key="image.id" class="gallery-item" @click="openModal(image)">
          <img :src="require(`@/assets/gallery/${image.path}`)" :alt="image.name" />
          <div class="overlay">
            <div class="info">
              <h3>{{ image.name }}</h3>
              <div class="icons">
                <a :href="image.fullViewUrl" target="_blank" class="icon view-full" title="View Full Image">
                  <i class="fa fa-search-plus"></i>
                </a>
                <a :href="image.galleryPageUrl" target="_blank" class="icon view-gallery" title="View in Gallery">
                  <i class="fa fa-image"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    <!-- Modal for Full Image -->
    <div v-if="isModalOpen" class="image-modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img :src="require(`@/assets/gallery/${selectedImage.path}`)" :alt="selectedImage.name" />
        <button class="close-button" @click="closeModal">Close</button>
      </div>
    </div>

    </div>
  </section>
</template>

<script>
import galleryImages from '@/assets/gallery-images.json';

export default {
  props: {
    imageIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isModalOpen: false,
      images: galleryImages,
      currentCategory: 'all',
    };
  },
  computed: {
    filteredImages() {
      let imagesToShow = this.images;
      console.log(this.images)

      if (this.imageIds.length > 0) {
         imagesToShow = imagesToShow.filter(image => this.imageIds.includes(String(image.id)));
      }

      
      if (this.currentCategory !== 'all') {
        imagesToShow = imagesToShow.filter(image => image.category === this.currentCategory);
      }

      return imagesToShow;
    }
  },
  methods: {
    filterCategory(category) {
      this.currentCategory = category;
    },
    openModal(image) {
      this.selectedImage = image;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.selectedImage = null;
    },
    viewFullImage() {
      // Logic to view full image
    },
    viewInGalleryPage() {
      // Logic to view in gallery page
    }
  }
};
</script>

<style scoped>
.gallery-section {
  padding: 2rem 1rem;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.category-menu {
  text-align: center;
  margin-bottom: 1.5rem;
}

.category-menu button {
  background: none;
  border: none;
  font-size: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.category-menu button.active,
.category-menu button:hover {
  background-color: #e84545;
  color: #fff;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  padding-top: 100%; /* This maintains the 1:1 aspect ratio */
}

.gallery-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area while maintaining aspect ratio */
  border-radius: 10px;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.1);
  filter: brightness(70%);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 1rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .overlay {
  opacity: 1;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info h3 {
  margin-bottom: 0.5rem;
}

.icons {
  display: flex;
  gap: 1rem;
}

.icon {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #e84545;
}

/* Modal styles */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e84545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}
</style>
