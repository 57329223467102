<template>
  <div>
    <PageHeading title="Products" subtitle="Explore Our Range of High-Quality Imports" />
    <ProductsList />
  </div>
</template>

<script>
import PageHeading from '@/components/PageHeading.vue';
import ProductsList from '@/components/ProductsList.vue';

export default {
  components: {
    PageHeading,
    ProductsList
  }
}
</script>