<template>
  <div>
    <PageHeading title="Contact Us" subtitle="Connect with Us for Inquiries and Partnerships" />
    <ContactForm />
  </div>
</template>

<script>
import PageHeading from '@/components/PageHeading.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
  components: {
    PageHeading,
    ContactForm
  }
}
</script>