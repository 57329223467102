<template>
  <section class="about-section">
    <div class="about-container">
      <!-- Left Side Content -->
      <div class="left-content">
        <h2>About Us</h2>
        <h3>Why Abir International?</h3>
        <h3>Abir International is the Biggest<br/>Trade Company in <span style="color:#e84545">Wood Polish Industries</span> from <span style="color:green">Bangladesh</span></h3>
        <p>
          We organize and import more than 15 explosive and chemical items from different business icon countries! We have successfully done business with the United Arab Emirates, Dubai, Iran, Singapore, Malaysia, Thailand, China, India, and more! Spreading our valuable fame to other countries with great sincerity and responsibility!
        </p>
        <p>
          With a commitment to excellence, we meticulously source and import high-quality explosives and chemical items, ensuring that each product meets stringent international standards. Our extensive network spans across major global markets, fostering strong partnerships and trust with leading businesses worldwide. Our dedication to delivering top-notch products with unmatched reliability has solidified our reputation, allowing us to expand our reach and influence in the industry with integrity and responsibility.
        </p>
        <router-link to="/contact"><button class="cta-button">Get in Touch</button></router-link>
      </div>

      <!-- Right Side Content Boxes -->
      <div class="right-content">
        <div class="info-box" v-for="(box, index) in infoBoxes" :key="index">
          <div class="info-text">
            <i :class="box.icon"></i>
            <h4>{{ box.title }}</h4>
            <p>{{ box.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const infoBoxes = [
  {
    icon: "fas fa-globe",
    title: "Biggest Trade Company",
    description: "Abir International is the biggest trade company in wood polish industries from Bangladesh! We import 42 explosive and chemical items from different countries.",
  },
  {
    icon: "fas fa-flask",
    title: "Reliable Chemical Importer",
    description: "We import 42 explosive and chemical items from various countries, maintaining strong partnerships, quality standards, and industry expertise.",
  },
  {
    icon: "fas fa-handshake",
    title: "Worldwide Business Partners",
    description: "We have successfully partnered with companies in many countries, expanding our fame with sincerity and responsibility. Let's import with us from today!",
  },
  {
    icon: "fas fa-tree",
    title: "Dominating Wood Polish Industries",
    description: "We are dominating the wood polish industries in Bangladesh by ensuring quality products and timely deliveries with exceptional service and reliability.",
  },
];
</script>

<style scoped>
.about-section {
  padding: 80px 20px;
  background: linear-gradient(135deg, #fff 50%, #f1f1f1 50%);
}

.about-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.left-content {
  flex: 1;
  max-width: 45%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transform: translateX(-50px);
  animation: slideInLeft 0.8s ease forwards;
}

.right-content {
  flex: 1;
  max-width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  transform: translateX(50px);
  animation: slideInRight 0.8s ease forwards;
}

.left-content h2 {
  font-size: 2.5rem;
  color: #e84545;
  margin-bottom: 20px;
}

.left-content h3 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  color: #333;
}

.left-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
}

.cta-button {
  background-color: #e84545;
  color: #ffffff;
  border: none;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #c33434;
  transform: translateY(-3px);
}

.info-box {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 0.5s ease forwards;
}

.info-box:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.info-text {
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text within the elements */
}

.info-text h4 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 8px;
}

.info-text p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.info-text i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px; /* Set width */
  height: 80px; /* Set height to match width for 1:1 aspect ratio */
  font-size: 1.8rem; /* Adjust font size as needed */
  color: #e84545;
  border: 2px solid #e84545;
  border-radius: 10%;
  padding: 10px;
  margin-bottom: 15px; /* Adds space between the icon and the text */
  transition: background-color 0.3s ease, border-color 0.3s ease;
  box-sizing: border-box; /* Ensures padding and border are included in total width and height */
}

.info-text:hover i {
  background-color: #e84545;
  color: #ffffff;
  border-color: #ffffff;
}

/* Animations */
@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
