<template>
  <footer class="footer-section">
    <div class="footer-container">
      <!-- Company Info Section -->
      <div class="footer-info">
        <h3>Abir International</h3>
        <p>
          Abir International is the biggest trade company in the wood polish industries from Bangladesh! We organize and import 42 explosive and chemical items from different business icon countries like the United Arab Emirates, Dubai, Iran, Singapore, Malaysia, Thailand, Bangkok, China, and India!
        </p>
      </div>

      <!-- Useful Links Section -->
      <div class="footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/products">Products</router-link></li>
          <li><router-link to="/gallery">Gallery</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>

      <!-- We Import Section -->
      <div class="footer-import">
        <h4>We Import</h4>
        <ul>
          <li>Methanol</li>
          <li>Wood Polish</li>
          <li>Thinner AAA</li>
          <li>Denatured Spirits</li>
          <li>Ethyl Acetate</li>
        </ul>
      </div>

      <!-- Contact Section -->
      <div class="footer-contact">
        <h4>Contact Us</h4>
        <p>Kholamora Bazar, Dhaka 1209, Bangladesh</p>
        <p>Phone: <a href="tel:+8801731103126">(+880) 1731 10 3126</a></p>
        <p>Email: <a href="mailto:info@abirinternational.org">info@abirinternational.org</a></p>
      </div>
    </div>

    <!-- Footer Credit Section -->
    <div class="footer-credit-container">
      <p class="footer-credit">
        Design and Developed by <a href="https://cyber.com.bd">Cyber Bangladesh</a>
      </p>
    </div>
  </footer>
</template>

<style scoped>
.footer-section {
  background-color: #070524;
  color: #ffffff;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 5px solid #e84b37;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  box-sizing: border-box;
}

.footer-container {
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.footer-credit-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  /* padding: 10px 0; */
}

.footer-info {
  grid-column: span 2;
}

.footer-info h3 {
  font-size: 1.8rem;
  color: #e84b37;
  margin-bottom: 10px;
}

.footer-info p {
  font-size: 1rem;
  line-height: 1.6;
}

.footer-links,
.footer-import,
.footer-contact {
  margin-top: 10px;
}

.footer-links h4,
.footer-import h4,
.footer-contact h4 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #e84b37;
}

.footer-links ul,
.footer-import ul {
  list-style: none;
  padding: 0;
}

.footer-links li,
.footer-import li {
  margin-left: 10%;
  margin-bottom: 5px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #e84b37;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-contact a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-contact a:hover {
  color: #e84b37;
}

.footer-credit {
  text-align: center;
  font-size: 0.9rem;
  color: #ffffff;
}

.footer-credit a {
  color: #e84b37;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.footer-credit a:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .footer-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .footer-info {
    grid-column: span 2;
  }
}

@media (max-width: 900px) {
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-info {
    grid-column: span 2;
  }

  .footer-links,
  .footer-import,
  .footer-contact {
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .footer-container {
    grid-template-columns: 1fr;
  }

  .footer-info,
  .footer-links,
  .footer-import,
  .footer-contact {
    grid-column: span 1;
    text-align: center;
    margin-top: 20px;
  }

  .footer-links li,
  .footer-import li {
    margin-left: 0;
  }
}
</style>