<template>
  <div>
    <PageHeading title="About Us" subtitle="Discover Our Journey and Values" />
    <AboutSection />
  </div>
</template>

<script>
import PageHeading from '@/components/PageHeading.vue';
import AboutSection from '@/components/AboutSection.vue';

export default {
  components: {
    PageHeading,
    AboutSection
  }
}
</script>
