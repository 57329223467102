<template>
  <div>
    <SliderSection />
    <AboutSection />
    <h2 style="margin-bottom: -40px">Products We Import</h2>
    <ProductsList />
    <h2 style="margin-bottom: -10px">Photo Gallery</h2>
    <GallerySection :image-ids="['20', '17', '6', '64', '61', '66', '97', '18', '54', '28', '58', '80', '12', '39', '60', '91', '42', '15', '2', '56', '94', '65', '85', '32']" />
    <h2>Frequently Asked Questions</h2>
    <FAQSection />
  </div>
</template>

<script>
  import SliderSection from '../components/SliderSection.vue';
  import FAQSection from '@/components/FAQSection.vue';
  import AboutSection from '@/components/AboutSection.vue';
  import GallerySection from '@/components/GallerySection.vue';
  import ProductsList from '@/components/ProductsList.vue';

export default {
  components: {
    SliderSection,
    FAQSection,
    AboutSection,
    ProductsList,
    GallerySection
  }
}
</script>

<style scoped>
  h2 {
    font-size: 1.8rem;
    color: #e84545;
    text-align: center;
    margin-bottom: -50px;
  }
</style>