<template>
  <section class="products-list">
    <div class="container">
      <p class="details">We organize and import 42 explosive and chemical items from different business Icon countries! Here's a list of some products we import.</p>
      
      <div class="products-grid">
        <div v-for="(product, index) in products" :key="index" class="product-item">
          <div class="icon">
            <i class="fas fa-flask"></i>
          </div>
          <div class="product-name">{{ product }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProductsList',
  data() {
    return {
      products: [
        'METHANOL',
        'WOOD POLISH',
        'THINNER AAA',
        'DENATURED SPIRITS',
        'ETHYL ACETATE',
        'GLYCERINE',
        'IPA',
        'TOLOIN',
        'ACITONE',
        'Methyl Ethyl Ketone (MEK)',
        'XILIN',
        'Methyl isobutyl ketone (MIBK)',
        'N.BUTANOL',
        'GUM COPAL',
        'GUM ROSIN',
        'LAC BUTTON (GALA)',
      ],
    };
  },
};
</script>

<style scoped>
.products-list {
  padding: 40px 20px;
  background-color: #f8f9fa;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.details {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.product-item {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 1.5rem;
  color: #e84545; /* Primary color */
  margin-right: 10px;
}

.product-name {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

@media (max-width: 768px) {
  .details {
    font-size: 1rem;
  }

  .product-item {
    flex-direction: column;
    align-items: center;
  }

  .icon {
    margin-bottom: 10px;
  }
}
</style>
